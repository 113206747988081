import request from './request';

export function upload(params){
    return request({
        url: 'file/upload',
        method: 'post',
        data: params
    });
}
export function getRemoteImage(params){
    return request({
        url: 'file/getRemoteImage',
        method: 'post',
        data: params
    });
}
