<template>
    <div>
        <editor id="tinymce" v-model="myValue"
                :init="init" :disabled="disabled"
                @onClick="onClick"></editor>
    </div>
</template>

<script>
    //引入基础文件：
    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver'

    // 编辑器插件plugins
    // 更多插件参考：https://www.tiny.cloud/docs/plugins/
    import 'tinymce/plugins/image' // 插入上传图片插件
    import 'tinymce/plugins/link' // 插入链接
    import 'tinymce/plugins/code' // 插入代码
    import 'tinymce/plugins/preview' // 插入预览
    import 'tinymce/icons/default/icons'

    import 'tinymce/plugins/media' // 插入视频插件
    import 'tinymce/plugins/table' // 插入表格插件
    import 'tinymce/plugins/lists' // 列表插件
    import 'tinymce/plugins/contextmenu'
    import 'tinymce/plugins/wordcount' // 字数统计插件
    import 'tinymce/plugins/paste' // 粘贴
    import 'tinymce/plugins/autolink' // 链接
    import 'tinymce/plugins/insertdatetime' // 链接
    import 'tinymce/plugins/hr' // 分割线
    import 'tinymce/plugins/colorpicker'
    import 'tinymce/plugins/textcolor'
    import axios from 'axios'

    import {
        upload
    } from '@/api/image.js'
    export default {
        name: "tinymce",
        components: {
            Editor
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                myValue: this.value,
                init: {
                    selector: '.tinymce',
                    language_url: '/tinymce/langs/zh_CN.js', // 语言包的路径
                    language: 'zh_CN', //语言
                    skin_url: '/tinymce/skins/ui/oxide', // skin路径
                    height: 500, //编辑器高度
                    branding: false, //是否禁用“Powered by TinyMCE”
                    menubar: false, //顶部菜单栏显示
                    elementpath: false, //禁用编辑器底部的状态栏
                    paste_data_images: true, // 允许粘贴图像
                    image_advtab: true,
                    convert_urls: false,
                    plugins: ['image', 'link', 'code', 'preview', 'media', 'table', 'paste', 'wordcount', 'lists', ], //插件
                    toolbar: [
                        'bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat'
                    ],
                    images_upload_handler: (blobInfo, success, failure) => {
                        this.handleImageAdded(blobInfo, success, failure)
                    }
                },
            }
        },
        mounted() {
            tinymce.init({})

        },
        activated() {
            tinymce.init({})
        },
        watch: {
            //动态传参
            value(newValue) {
                this.myValue = newValue
            },
            //读取输入框内的数据
            myValue(newValue) {
                this.$emit('input', newValue)
            }
        },
        methods: {
            //需要什么事件可以自己增加
            onClick(e) {
                this.$emit('onClick', e, tinymce)
            },
            //可以添加一些自己的自定义事件，如清空内容
            clear() {
                this.myValue = ''
            },
            handleImageAdded(blobInfo, success, failure) {
                let file = blobInfo.blob() //数据流
                let formdata = new FormData()
                formdata.set('file', file);
                formdata.set('dir', '/product');
                upload(formdata).then(function(res) {
                    if(res.message){
                        failure(res.message);
                    } else {
                        success(res.url)
                    }
                });
            },
        }
    }
</script>
